exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-index-js": () => import("./../../../src/pages/ar/index.js" /* webpackChunkName: "component---src-pages-ar-index-js" */),
  "component---src-pages-bg-index-js": () => import("./../../../src/pages/bg/index.js" /* webpackChunkName: "component---src-pages-bg-index-js" */),
  "component---src-pages-bn-index-js": () => import("./../../../src/pages/bn/index.js" /* webpackChunkName: "component---src-pages-bn-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-el-index-js": () => import("./../../../src/pages/el/index.js" /* webpackChunkName: "component---src-pages-el-index-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-hi-index-js": () => import("./../../../src/pages/hi/index.js" /* webpackChunkName: "component---src-pages-hi-index-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hu-index-js": () => import("./../../../src/pages/hu/index.js" /* webpackChunkName: "component---src-pages-hu-index-js" */),
  "component---src-pages-id-index-js": () => import("./../../../src/pages/id/index.js" /* webpackChunkName: "component---src-pages-id-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-index-js": () => import("./../../../src/pages/it/index.js" /* webpackChunkName: "component---src-pages-it-index-js" */),
  "component---src-pages-ja-index-js": () => import("./../../../src/pages/ja/index.js" /* webpackChunkName: "component---src-pages-ja-index-js" */),
  "component---src-pages-ko-index-js": () => import("./../../../src/pages/ko/index.js" /* webpackChunkName: "component---src-pages-ko-index-js" */),
  "component---src-pages-mp-3-music-js": () => import("./../../../src/pages/mp3-music.js" /* webpackChunkName: "component---src-pages-mp-3-music-js" */),
  "component---src-pages-ms-index-js": () => import("./../../../src/pages/ms/index.js" /* webpackChunkName: "component---src-pages-ms-index-js" */),
  "component---src-pages-my-index-js": () => import("./../../../src/pages/my/index.js" /* webpackChunkName: "component---src-pages-my-index-js" */),
  "component---src-pages-nl-index-js": () => import("./../../../src/pages/nl/index.js" /* webpackChunkName: "component---src-pages-nl-index-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-pt-index-js": () => import("./../../../src/pages/pt/index.js" /* webpackChunkName: "component---src-pages-pt-index-js" */),
  "component---src-pages-ro-index-js": () => import("./../../../src/pages/ro/index.js" /* webpackChunkName: "component---src-pages-ro-index-js" */),
  "component---src-pages-ru-index-js": () => import("./../../../src/pages/ru/index.js" /* webpackChunkName: "component---src-pages-ru-index-js" */),
  "component---src-pages-th-index-js": () => import("./../../../src/pages/th/index.js" /* webpackChunkName: "component---src-pages-th-index-js" */),
  "component---src-pages-tl-ph-index-js": () => import("./../../../src/pages/tl-ph/index.js" /* webpackChunkName: "component---src-pages-tl-ph-index-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-tr-index-js": () => import("./../../../src/pages/tr/index.js" /* webpackChunkName: "component---src-pages-tr-index-js" */),
  "component---src-pages-vi-index-js": () => import("./../../../src/pages/vi/index.js" /* webpackChunkName: "component---src-pages-vi-index-js" */),
  "component---src-pages-zh-cn-index-js": () => import("./../../../src/pages/zh-cn/index.js" /* webpackChunkName: "component---src-pages-zh-cn-index-js" */),
  "component---src-pages-zh-tw-index-js": () => import("./../../../src/pages/zh-tw/index.js" /* webpackChunkName: "component---src-pages-zh-tw-index-js" */)
}

